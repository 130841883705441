<template>
  <b-container fluid>
    <div class="auth-card auth-card-container">
      <img
        id="profile-img"
        src="@/assets/mnwd_logo.png"
        class="auth-card-img"
      />
      <template v-if="request && !request_sent">
        <form v-on:submit.prevent="handlePasswordResetRequest">
          <h5>Forgot Your Password?</h5>
          <span
            >Enter the email associated with your account to reset your
            password.</span
          >

          <label>Email</label>
          <b-form-group :invalid-feedback="invalidEmailFeedback">
            <b-form-input
              id="nested-email"
              :state="emailState"
              v-model="email"
              @blur="emailBlur = true"
            ></b-form-input>
          </b-form-group>

          <b-button
            type="submit"
            :disabled="!isValidEmail() || loading"
            variant="primary"
          >
            SUBMIT
          </b-button>
        </form>
      </template>
      <template v-else-if="request && request_sent">
        <h5>Please Check Your Email</h5>
        <span
          >An email has been sent to {{ email }} with a link to reset your
          password.</span
        >
      </template>
      <template v-else-if="!password_reset">
        <h5>Reset Your Password</h5>

        <label>Enter New Password</label>
        <form v-on:submit.prevent="handlePasswordReset">
          <b-form-group :invalid-feedback="invalidPasswordFeedback">
            <b-form-input
              id="nested-password"
              :state="passwordState"
              v-model="password"
              type="password"
              @blur="passwordBlur = true"
            ></b-form-input>
          </b-form-group>

          <label>Confirm New Password</label>
          <b-form-group :invalid-feedback="invalidRepeatPasswordFeedback">
            <b-form-input
              id="nested-repeat-password"
              :state="repeatPasswordState"
              v-model="repeatPassword"
              type="password"
              @blur="repeatPasswordBlur = true"
            ></b-form-input>
          </b-form-group>

          <b-button
            type="submit"
            :disabled="!repeatPasswordState || loading"
            variant="primary"
            @click="handlePasswordReset"
          >
            RESET
          </b-button>
        </form>
      </template>
      <template v-else>
        <h5>Password Reset Successfully</h5>
        <span
          >Your password has successfully been reset. Click the link below to
          sign in with your new credentials.</span
        >
        <b-button variant="primary" :to="{ name: 'signIn' }" class="mt-3">
          SIGN IN
        </b-button>
      </template>
    </div>
    <div class="auth-card auth-card-container" v-if="request">
      <template v-if="request && !request_sent">
        Have an account? <router-link to="/signIn">Sign In</router-link>
      </template>
      <template v-else-if="request_sent">
        Didn’t receive an email?
        <router-link @click.native="handlePasswordResetRequest" to="/"
          >Resend</router-link
        >
      </template>
    </div>
  </b-container>
</template>

<script>
import validator from "@/mixins/validator";
import { reset_password, reset_password_request } from "@/apis/auth.js";
export default {
  name: "ResetPassword",
  mixins: [validator],
  data() {
    return {
      request_sent: false,
      password_reset: false,
      email: "",
      loading: false,
      password: "",
      repeatPassword: "",
    };
  },
  computed: {
    token() {
      return this.$route.params.token;
    },
    request() {
      return !this.token;
    },
  },
  methods: {
    handlePasswordReset() {
      this.password &&
        reset_password(this.token, this.password)
          .then(() => {
            this.password_reset = true;
          })
          .catch((e) => {
            this.notify("danger", "Password could not be reset", "" + e);
          });
    },
    handlePasswordResetRequest() {
      this.email &&
        reset_password_request(this.email)
          .then(() => {
            this.request_sent = true;
          })
          .catch((e) => {
            this.notify(
              "danger",
              "Password reset request could not be sent",
              "" + e
            );
          });
    },
  },
};
</script>
